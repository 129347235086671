type URIBuilderArgs = {
  repoSlug: string;
  gitCommitRef: string;
  gitRepoOwner: string;
};

// Vercel's VERCEL_URL didn't fit our needs for workOS, so this preview
// URL builder is meant to create a URL we can use that will also work
// with workOS
export const previewURIBuilder = ({
  repoSlug,
  gitCommitRef,
  gitRepoOwner,
}: URIBuilderArgs) => {
  return `${repoSlug}-git-${gitCommitRef
    ?.toLowerCase()
    ?.replace("/", "-")}-${gitRepoOwner}.vercel.app`;
};

export const previewURLBuilder = ({
  repoSlug,
  gitCommitRef,
  gitRepoOwner,
}: URIBuilderArgs) => {
  return `https://${previewURIBuilder({
    repoSlug,
    gitCommitRef,
    gitRepoOwner,
  })}`;
};

/**
 * Gets the current hostname of a preview environment and includes the protocol
 * @returns string
 */
const previewURL = () => {
  return previewURLBuilder({
    repoSlug: process.env.VERCEL_GIT_REPO_SLUG,
    gitCommitRef: process.env.VERCEL_GIT_COMMIT_REF,
    gitRepoOwner: process.env.VERCEL_GIT_REPO_OWNER,
  });
};

/**
 * Gets the current hostname of a preview environment within the protocol
 * @returns string
 */
export const previewURI = () => {
  return previewURIBuilder({
    repoSlug: process.env.VERCEL_GIT_REPO_SLUG,
    gitCommitRef: process.env.VERCEL_GIT_COMMIT_REF,
    gitRepoOwner: process.env.VERCEL_GIT_REPO_OWNER,
  });
};

export default previewURL;
