import previewURL from "~/utils/vercelPreviewURLBuilder";

/**
 * Returns the hostname and protocol of the current environment
 */
export default function getHostURI() {
  let HOST_URI = "http://localhost:3000";

  // NODE_ENV in Vercel is always "production", this is a guard
  // so that we don't build a new HOST_URI in development
  if (process.env.NODE_ENV === "production") {
    // yes this is janky...but it's the only way to set the preview login environment
    // URI correctly
    if (isLoginEnvironmentForPreviews()) {
      return "https://preview-login.murmur.com";
    }

    HOST_URI =
      // IS_PRODUCTION is Vercel's variable for production. If this is
      // false, this is a preview environment
      process.env.IS_PRODUCTION ? "https://app.murmur.com" : previewURL();
  }

  return HOST_URI;
}

/**
 * Returns whether or not the current environment is a preview environment
 * @returns boolean
 */
export function isPreviewEnvironment(hostname?: string): boolean {
  return !(process.env.NEXT_PUBLIC_IS_PRODUCTION || hostname === "localhost");
}

/**
 * Returns boolean of whether the current environment is the login environment
 * for preview environments
 * for all preview environments
 * @returns boolean
 */
export function isLoginEnvironmentForPreviews() {
  return process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === "preview-login";
}

/**
 * Returns a URI based on the host URI. Expects a preceding slash (e.g. `/users`)
 */
export function getAppLink(path: string) {
  return getHostURI() + path;
}
