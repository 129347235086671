import Link from "next/link";
import { useRouter } from "next/router";
import React, { ComponentType, useEffect, useState } from "react";
import styled, { css, useTheme } from "styled-components";

import GoogleLogo from "~/assets/icons/google.svg";
import Button from "~/components/Button";
import { LoginWithWallet } from "~/components/LoginBox/LoginWithWallet";
import Logo from "~/components/Logo";
import {
  addRedirectPath,
  getRedirectPath,
  loginAPIEndpoint,
  loginPageLink,
} from "~/shared/utils/postLoginRedirectPath";
import {
  isLoginEnvironmentForPreviews,
  isPreviewEnvironment,
} from "~/utils/getHostURI";
import { validateEmailAddress } from "~/utils/validators/email";

function useRedirectPath({ redirectPath }: { redirectPath?: string }) {
  const { query } = useRouter();

  if (redirectPath) {
    return redirectPath;
  }

  redirectPath = getRedirectPath(query);

  return redirectPath;
}

const DisclaimerContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 2rem;
  width: 100%;
  p {
    max-width: 15rem;
    display: block;
  }
  a {
    color: ${({ theme }) => theme.colors.dodgerBlue10Darker};
    text-decoration: none;
    &:visited,
    &:hover:visited {
      color: ${({ theme }) => theme.colors.speech};
    }
    &:active:visited {
      color: ${({ theme }) => theme.colors.murple10Darker};
    }
    &:active {
      color: ${({ theme }) => theme.colors.dodgerBlue20Darker};
    }
    &:hover {
      color: ${({ theme }) => theme.colors.dodgerBlue10Darker};
      text-decoration: underline;
    }
  }
`;

export function LoginBox({
  error,
  prompt: Prompt = DefaultPrompt,
  redirectPath,
  topMessage: TopMessage,
  pageType = "login",
}: {
  error?: { message: string | undefined };
  pageType?: "login" | "signup";
  prompt?: ComponentType;
  redirectPath?: string;
  topMessage?: ComponentType;
}) {
  return (
    <div
      css={({ theme }) => ({
        backgroundColor: theme.colors.white,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "2.5rem",
        width: 400,
        maxWidth: "86vw",
      })}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "2rem",
        }}
      >
        <Logo
          css={({ theme }) => ({
            color: theme.colors.speech,
            marginTop: "1rem",
            marginBottom: ".5rem",
          })}
        />
        <Prompt />
      </div>
      {TopMessage ? <TopMessage /> : null}
      <h2 css={{ marginTop: 0, fontWeight: "normal", fontSize: "14px" }}>
        {pageType === "login" ? <>Sign in</> : <>Sign up</>} to continue to
        Murmur
      </h2>
      {error && <ErrorWrap>{error.message}</ErrorWrap>}
      <LoginPicker redirectPath={redirectPath} pageType={pageType} />

      {pageType === "login" && (
        <div css={{ marginTop: "1rem" }}>
          <Link href="/signup">Sign up instead</Link>
        </div>
      )}

      <DisclaimerContainer>
        <p>
          By continuing you agree to our{" "}
          <a
            href="https://www.murmur.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            href="https://www.murmur.com/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>
          .
        </p>
      </DisclaimerContainer>
    </div>
  );
}

function LoginPicker({
  redirectPath,
  pageType,
}: {
  redirectPath?: string;
  pageType: "login" | "signup";
}) {
  if (typeof window === "undefined") return null;

  if (
    isPreviewEnvironment(window.location.hostname) &&
    !isLoginEnvironmentForPreviews()
  ) {
    // only show preview redirect box if this is a preview environment that's _not_
    // the preview environment that's used specifically for logging in
    return <LoginPreviewRedirect />;
  }
  return <LoginForm redirectPath={redirectPath} pageType={pageType} />;
}

function Or() {
  return (
    <div
      css={{
        width: "100%",
        position: "relative",
        display: "flex",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >
      <hr
        css={({ theme }) => ({
          border: "none",
          borderTop: `1px solid ${theme.colors.moon}`,
          width: "100%",
          position: "absolute",
          top: 0,
        })}
      />
      <div
        css={({ theme }) => ({
          textTransform: "uppercase",
          backgroundColor: theme.colors.white,
          display: "inline-block",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          margin: "0 auto",
          position: "relative",
        })}
      >
        or
      </div>
    </div>
  );
}

function LoginForm({
  redirectPath: initialRedirectPath,
  pageType,
}: {
  redirectPath?: string;
  pageType: "login" | "signup";
}) {
  const redirectPath = useRedirectPath({ redirectPath: initialRedirectPath });
  const { query } = useRouter();
  const betaInvitation = (query.betaInvitation as string) ?? "";

  return (
    <>
      <LoginWithGoogle
        betaInvitation={betaInvitation}
        redirectPath={redirectPath}
      />
      <Or />
      <MagicLinkForm
        betaInvitation={betaInvitation}
        redirectPath={redirectPath}
        submitText={
          pageType === "login" ? "Sign In With Email" : "Sign Up With Email"
        }
      />
      <Or />
      <LoginWithWallet pageType={pageType} />
    </>
  );
}

function LoginWithGoogle({
  betaInvitation,
  redirectPath,
}: {
  betaInvitation?: string;
  redirectPath?: string;
}) {
  return (
    <a
      title="Login with Google (redirects to Google authentication)"
      css={({ theme }) => ({
        display: "flex",
        padding: "1rem",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: theme.colors.moonMedium,
        width: "100%",
        borderRadius: "4px",
        textDecoration: "none",
        color: theme.colors.oil,
      })}
      href={loginAPIEndpoint({ betaInvitation, redirectPath })}
    >
      <GoogleLogo width="18" height="19" css={{ marginRight: ".5rem" }} />
      Continue with Google
    </a>
  );
}

const OffScreen = styled.label`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  user-select: none;
`;

const InputWrap = styled.div`
  margin-bottom: 1rem;
`;

const MagicLinkTextInput = styled.input<{
  $hasError: boolean;
}>`
  ${({ theme, $hasError }) => {
    return css`
      display: block;
      width: 100%;
      padding: 1rem;
      border-style: solid;
      border-width: 1px;
      border-color: ${$hasError
        ? theme.colors.cinnabar
        : theme.colors.moonMedium};
      border-radius: ${$hasError ? `4px 4px 0 0` : `4px`};
      text-decoration: none;
      color: ${theme.colors.oil};
    `;
  }}
`;

const ErrorWrap = styled.div`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.cinnabarLight};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.cinnabar};
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
`;

const ErrorWrapAttached = styled(ErrorWrap)`
  border-top-width: 0;
  border-radius: 0 0 4px 4px;
`;

function validateEmailInput(email: string) {
  if (!email) {
    return "Please fill in your email address";
  }
  if (!validateEmailAddress(email)) {
    return "Sorry, but this doesn't look like a valid email address.";
  }
  return null;
}

function MagicLinkForm({
  betaInvitation,
  redirectPath,
  submitText,
}: {
  betaInvitation?: string;
  redirectPath?: string;
  submitText: string;
}) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<null | string>();
  const [success, setSuccess] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const checkIsValid = validateEmailInput(email);

    if (!checkIsValid) {
      setError(null);
    }
  }, [email]);

  return (
    <form
      css={{ width: "100%" }}
      onSubmit={async (e) => {
        e.preventDefault();

        const checkIsValid = validateEmailInput(email);

        if (checkIsValid) {
          return setError(checkIsValid);
        }

        setIsSubmitting(true);
        const response = await fetch(`/api/magicLink`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(
            addRedirectPath(
              { ...(betaInvitation && { betaInvitation }), email },
              redirectPath
            )
          ),
        });

        if (response.status !== 200) {
          return setError(response.statusText);
        }

        setSuccess(true);
        setIsSubmitting(false);
      }}
    >
      {success ? (
        <div css={{ padding: "1rem", lineHeight: "1.5rem" }}>
          If an account for {email} exists, you&apos;ll receive an email with
          the link.
          <small
            css={{
              display: "block",
              lineHeight: "1.15rem",
              marginTop: "1rem",
            }}
          >
            If you are on a slow internet connection or a corporate connection,
            give the email up to 5 minutes before trying again.
          </small>
        </div>
      ) : (
        <>
          <OffScreen css={{ display: "none" }} htmlFor="magiclinkEmail">
            Email
          </OffScreen>
          <InputWrap>
            <MagicLinkTextInput
              id="magiclinkEmail"
              name="magiclinkEmail"
              $hasError={!!error}
              value={email}
              onChange={({ target: { value } }) => {
                setEmail(value);
              }}
              disabled={isSubmitting}
              placeholder="Your work email"
              type="text"
              autoComplete="off"
            />
            {error && <ErrorWrapAttached>{error}</ErrorWrapAttached>}
          </InputWrap>
          <Button
            disabled={isSubmitting}
            css={({ theme }) => ({
              padding: "1rem",
              borderStyle: "solid",
              borderWidth: "1px",
              backgroundColor: theme.colors.moon,
              fontWeight: theme.weights.regular,
              borderColor: theme.colors.moonMedium,
              borderRadius: "4px",
              textDecoration: "none",
              color: theme.colors.oil,
              cursor: "pointer",
              "&:disabled": {
                cursor: "default",
              },
            })}
            type="submit"
            value={submitText}
          >
            {submitText}
          </Button>
        </>
      )}
    </form>
  );
}

// This is our hacky way of using workOS across all preview environments without
// having to update the redirect URI in the workOS config UI.
// This sends the hostname for the preview environment you're on to the api/login
// endpoint of the preview environment login hostname as the redirect_host
function LoginPreviewRedirect() {
  const { query } = useRouter();
  if (typeof window === "undefined") return null;
  const redirectPath = getRedirectPath(query);
  const { hostname, port, protocol } = window.location;
  const redirectHost = `${protocol}//${hostname}${port ? `:${port}` : ""}`;
  const previewAutoLoginLink = loginAPIEndpoint({
    redirectPath: redirectPath ? redirectHost + redirectPath : undefined,
    redirectHost,
    preview: true,
  });

  const previewLoginLink = loginPageLink({
    redirectPath: redirectPath ? redirectHost + redirectPath : undefined,
    redirectHost,
    preview: true,
  });

  return (
    <>
      <h1>PREVIEW ENV ONLY</h1>
      <a
        css={{ marginBottom: "1rem", textAlign: "center" }}
        href={previewAutoLoginLink}
      >
        Login Automatically Through Preview Environment
      </a>
      <a
        css={{ marginBottom: "1rem", textAlign: "center" }}
        href={previewLoginLink}
      >
        Go to Preview Login Environment&apos;s Login Page
      </a>
      <p>
        If you&apos;re making any changes to WorkOS, you also need to push your
        branch to the preview-login branch.
      </p>
    </>
  );
}

function DefaultPrompt() {
  const theme = useTheme();

  return (
    <h1
      css={{
        fontWeight: theme.weights.medium,
        fontSize: "24px",
        marginTop: 0,
        marginBottom: 0,
      }}
    >
      Welcome
    </h1>
  );
}
