import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import Web3Modal from "web3modal";

import Button from "~/components/Button";

export function LoginWithWallet({
  pageType,
}: {
  pageType: "login" | "signup";
}) {
  const [publicAddress, setPublicAddress] = useState<string | null>(null);
  const [signer, setSigner] = useState<ethers.providers.JsonRpcSigner | null>(
    null
  );

  const { asPath, push, query } = useRouter();

  async function connect() {
    try {
      const web3Modal = await getWeb3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      const signer = provider.getSigner();
      const accounts = await provider.listAccounts();

      setSigner(signer);
      setPublicAddress(accounts[0]);
    } catch (err) {
      console.error("error:", err);
    }
  }

  const authenticateUser = useCallback(
    async (publicAddress: string, signer: ethers.providers.JsonRpcSigner) => {
      const generateNonceResp = await fetch("/api/web3/generateNonce", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ walletId: publicAddress }),
      });

      const respJSON = await generateNonceResp.json();
      if (respJSON.nonce) {
        const message = await signer.signMessage(respJSON.nonce);
        const isLoginRoute = asPath.startsWith("/login");
        const redirect_path = isLoginRoute ? query.redirect_path : asPath;
        push({
          pathname: `/api/web3/login`,
          query: {
            publicAddress,
            message,
            ...(redirect_path && { redirect_path }),
          },
        });
      }
    },
    [asPath, push, query.redirect_path]
  );

  useEffect(() => {
    if (publicAddress && signer) {
      authenticateUser(publicAddress, signer);
    }
  }, [publicAddress, authenticateUser, signer]);

  return (
    <Button
      css={{
        padding: "1rem",
      }}
      variant="speech"
      onClick={connect}
    >
      {pageType === "login" ? <>Login</> : <>Sign Up</>} with Wallet
    </Button>
  );
}

async function getWeb3Modal() {
  const web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: false,
    providerOptions: {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: process.env.NEXT_PUBLIC_INFURA_ID,
        },
      },
    },
  });
  return web3Modal;
}
