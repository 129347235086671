/**
 * Returns if the email address is valid
 *
 * @katekourbatova and I did a little side quest to see what the longest TLD is
 * and we grabbed the data from https://data.iana.org/TLD/tlds-alpha-by-domain.txt
 * and pasted that into a var named `tld` in the console.
 * Then we ran this to determine the longest one:
 *
 * tlds.sort((a,b) => {if (a.length > b.length) { return -1} else if (a.length < b.length) {return 1} else {return 0}})
 *
 *  @param emailAddress string
 * @returns boolean
 */
export function validateEmailAddress(emailAddress: string) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z-]{2,24}$/i.test(
    emailAddress.trim()
  );
}
